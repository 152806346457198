import { inject, Injectable } from '@angular/core';
import { CanActivate, GuardResult, MaybeAsync } from '@angular/router';
import { SplashService } from '@context/frontend/common';

/**
 * We want to hide the splash on routes that do not need it, so during redirects we ensure
 * that the splash is no longer seen by using this guard
 */
@Injectable({ providedIn: 'root' })
export class SplashGuard implements CanActivate {
	private readonly splash = inject(SplashService);

	canActivate(): MaybeAsync<GuardResult> {
		this.splash.onHide();
		return true;
	}
}
