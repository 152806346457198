<section tuiContent [formGroup]="search">
	@if (loading) {
		<tui-loader size="xl" />
	} @else {
		@if (totalActivityGroup.length) {
			<tui-textfield
				tuiTextfieldSize="m"
				iconStart="@tui.search"
				size="s"
			>
				<input
					formControlName="term"
					[placeholder]="'action.search' | transloco"
					tuiTextfield
					tuiAutoFocus
				/>
			</tui-textfield>

			@if (activityGroup.length) {
				@for (group of activityGroup; track group.date) {
					<h3 class="margin-bottom-2" [class.margin-top-6]="!$first">
						{{ group.date }}
					</h3>

					@for (item of group.activity; track item.id) {
						<ctx-activity-row
							class="margin-bottom-4"
							[activity]="item"
							[user]="item.userData"
						></ctx-activity-row>
					}
				}
			} @else if (search.get('term')?.value) {
				<ctx-table-placeholder
					icon="@tui.search"
					[header]="'data.search-placeholder-header' | transloco"
					[content]="'data.search-placeholder-content' | transloco"
				></ctx-table-placeholder>
			}
		} @else {
			<ctx-table-placeholder
				icon="@tui.history"
				[header]="'data.activity-placeholder-header' | transloco"
				[content]="'data.activity-placeholder-content' | transloco"
			></ctx-table-placeholder>
		}
	}
</section>
