import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostBinding,
	inject,
	Input,
	OnInit,
} from '@angular/core';
import { getDoc } from '@angular/fire/firestore';
import { RelativeTimePipe } from '@context/frontend/common';
import { AvatarComponent, UserNamePipe } from '@context/frontend/user';
import {
	Notification,
	NotificationType,
} from '@context/shared/types/notification';
import { User } from '@context/shared/types/user';
import { TranslocoModule } from '@jsverse/transloco';
import { TuiHint, TuiIcon } from '@taiga-ui/core';
import { DocumentReference } from 'firebase/firestore';
import { QuillModule } from 'ngx-quill';

const DefaultNotificationIcon = '@tui.bell';
const NotificationIconMap: { [key in NotificationType]: string } = {
	'system-accepted-invite': '@tui.mail-check',
	'system-rejected-invite': '@tui.mail-minus',
	'user-shared-file': '@tui.share-2',
	'user-revoked-file': '@tui.user-round-x',
	'user-shared-folder': '@tui.share-2',
	'user-revoked-folder': '@tui.user-round-x',
	'user-added-log': '@tui.notebook-pen',
	'user-archived-log': '@tui.circle-minus',
};

@Component({
	standalone: true,
	imports: [
		AvatarComponent,
		RelativeTimePipe,
		QuillModule,
		TranslocoModule,
		TuiHint,
		UserNamePipe,
		TuiIcon,
	],
	selector: 'ctx-notification-row',
	templateUrl: 'notification-row.component.html',
	styleUrl: 'notification-row.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationRowComponent implements OnInit {
	@Input() notification!: Notification;

	sender: User | null = null;
	icon!: string;

	@HostBinding('attr.unread')
	get unread() {
		return !this.notification?.acknowledgedAt;
	}

	private readonly cdRef = inject(ChangeDetectorRef);

	ngOnInit() {
		this.icon =
			NotificationIconMap[this.notification.type] ??
			DefaultNotificationIcon;
		if (this.notification.createdBy) this.fetchSender();
	}

	async fetchSender() {
		if (!this.notification.createdBy) return;
		const doc = await getDoc(
			this.notification.createdBy as DocumentReference<User>,
		);
		this.sender = doc.data() as User;
		this.cdRef.detectChanges();
	}
}
