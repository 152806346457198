import { Capacitor } from '@capacitor/core';
import { Platform } from '@context/shared/types/native';

const MacOS = /(macintosh|macintel|macppc|mac68k|macos)/i;
const Windows = /(win32|win64|windows|wince)/i;
const iOS = /(iphone|ipad|ipod)/i;
const Android = /android/i;
const Linux = /linux/i;

/**
 * Checks the device that is currently rendering the experience and returns
 * `true` if the requested platform is equal to the device. Note that browser
 * based emulators do not reflect the emulated OS, they will return as `web`.
 *
 * @param platform the platform to check the device against
 */
export function isPlatform(platform: Platform): boolean {
	return getPlatform() === platform;
}

/**
 * Returns the devices current platform typed against our internally detected
 * platforms.
 */
export function getPlatform(): Platform {
	if (Capacitor.isNativePlatform()) {
		return Capacitor.getPlatform() as Platform;
	}

	// consolidate some of the values since we already determine its not native
	switch (getOS()) {
		case 'macos':
		case 'ios':
			return 'mac';
		default:
			return 'windows';
	}
}

/**
 * Determines what OS the user is currently running.
 *
 * @returns the os that the user is currently running
 */
export function getOS() {
	const ua = window.navigator.userAgent.toLowerCase();

	if (MacOS.test(ua)) {
		return 'macos';
	} else if (iOS.test(ua)) {
		return 'ios';
	} else if (Windows.test(ua)) {
		return 'windows';
	} else if (Android.test(ua)) {
		return 'android';
	} else if (Linux.test(ua)) {
		return 'linux';
	}

	return null;
}
