import { Alert, AlertId } from '../types';

export const Alerts: { [key in AlertId]: Alert } = {
	logout: {
		label: 'auth.alert.logout.success.label',
		content: 'auth.alert.logout.success.content',
		appearance: 'success',
	},
	'logout-error': {
		label: 'auth.alert.logout.error.label',
		content: 'auth.alert.logout.error.content',
		appearance: 'error',
	},
	'alerts-updated': {
		label: 'profile.alert.alerts-updated.success.label',
		content: 'profile.alert.alerts-updated.success.content',
		appearance: 'success',
	},
	'alerts-updated-error': {
		label: 'profile.alert.alerts-updated.error.label',
		content: 'profile.alert.alerts-updated.error.content',
		appearance: 'error',
	},
	'profile-updated': {
		label: 'profile.alert.profile-updated.success.label',
		content: 'profile.alert.profile-updated.success.content',
		appearance: 'success',
	},
	'profile-updated-error': {
		label: 'profile.alert.profile-updated.error.label',
		content: 'profile.alert.profile-updated.error.content',
		appearance: 'error',
	},
	'user-updated': {
		label: 'user.alert.user-updated.success.label',
		content: 'user.alert.user-updated.success.content',
		appearance: 'success',
	},
	'user-updated-error': {
		label: 'user.alert.user-updated.error.label',
		content: 'user.alert.user-updated.error.content',
		appearance: 'error',
	},
	'image-upload-error': {
		label: 'alert.file-upload.error.label',
		content: 'alert.file-upload.error.content',
		appearance: 'error',
	},
	notification: {
		label: 'notification.alert.success.label',
		appearance: 'info',
	},
	'user-not-found': {
		label: 'user.alert.not-found.error.label',
		content: 'user.alert.not-found.error.content',
		appearance: 'error',
	},
	'loading-data': {
		label: 'data.alert.loading.error.label',
		content: 'data.alert.loading.error.content',
		appearance: 'error',
	},
	'deleted-user': {
		label: 'user.alert.deleted-user.error.label',
		content: 'user.alert.deleted-user.error.content',
		appearance: 'error',
	},
	'user-deleted': {
		label: 'user.alert.user-deleted.error.label',
		content: 'user.alert.user-deleted.error.content',
		appearance: 'success',
	},
	'generic-error': {
		label: 'data.alert.generic.error.label',
		content: 'data.alert.generic.error.content',
		appearance: 'error',
	},
	'generic-success': {
		label: 'data.alert.generic.success.label',
		content: 'data.alert.generic.success.content',
		appearance: 'success',
	},
	'invite-not-found': {
		label: 'invite.alert.not-found.error.label',
		content: 'invite.alert.not-found.error.content',
		appearance: 'error',
	},
	'user-already-exists': {
		label: 'user.alert.already-exists.error.label',
		content: 'user.alert.already-exists.error.content',
		appearance: 'error',
	},
	'deleted-user-already-exists': {
		label: 'user.alert.deleted-already-exists.error.label',
		content: 'user.alert.deleted-already-exists.error.content',
		appearance: 'error',
	},
	'edit-permissions-error': {
		label: 'user.alert.edit-permissions.error.label',
		content: 'user.alert.edit-permissions.error.content',
		appearance: 'error',
	},
	'folder-not-found': {
		label: 'folder.alert.not-found.error.label',
		content: 'folder.alert.not-found.error.content',
		appearance: 'error',
	},
	'file-not-found': {
		label: 'file.alert.not-found.error.label',
		content: 'file.alert.not-found.error.content',
		appearance: 'error',
	},
	'deleted-folder': {
		label: 'folder.alert.deleted-folder.error.label',
		content: 'folder.alert.deleted-folder.error.content',
		appearance: 'error',
	},
	'deleted-file': {
		label: 'file.alert.deleted-file.error.label',
		content: 'file.alert.deleted-file.error.content',
		appearance: 'error',
	},
	'forgot-password': {
		label: 'user.alert.forgot-password.success.label',
		content: 'user.alert.forgot-password.success.content',
		appearance: 'success',
	},
	'forgot-password-expired': {
		label: 'user.alert.forgot-password-expired.error.label',
		content: 'user.alert.forgot-password-expired.error.content',
		appearance: 'error',
	},
	'copy-link': {
		label: 'alert.copy-link.success.label',
		content: 'alert.copy-link.success.content',
		appearance: 'success',
	},
	'copy-link-error': {
		label: 'alert.copy-link.error.label',
		content: 'alert.copy-link.error.content',
		appearance: 'error',
	},
	'inquire-sent': {
		label: 'alert.inquire-sent.success.label',
		content: 'alert.inquire-sent.success.content',
		appearance: 'success',
	},
	'help-sent': {
		label: 'alert.help-sent.success.label',
		content: 'alert.help-sent.success.content',
		appearance: 'success',
	},
};
