import { inject, Injectable } from '@angular/core';
import { Alert, AlertId, Alerts } from '@context/shared/types/alert';
import { TranslocoService } from '@jsverse/transloco';
import { TuiAlertOptions, TuiAlertService } from '@taiga-ui/core';
import { take } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AlertService {
	readonly defaultOptions: Partial<TuiAlertOptions> = {
		autoClose: 10000,
		closeable: true,
	};

	private readonly tuiAlertService = inject(TuiAlertService);
	private readonly transloco = inject(TranslocoService);

	/**
	 * Opens the specified pre-made alert based on the id provided. Although not
	 * recommended, a custom alert can be created too.
	 *
	 * @note by providing an id, you are requesting that base alert information. If additional
	 * configuration is provided as `alert`, whatever is provided will override the base properties
	 * found on the data that the `id` correlates to.
	 *
	 * @param id the id of the alert to open
	 * @param alert additional configurations that can be overridden on the base alert requested
	 */
	open(id: AlertId, alert?: Partial<Alert>) {
		alert = Object.assign({}, Alerts[id], alert);

		const label = alert.label
			? this.transloco.translate(alert.label)
			: undefined;

		const content = alert.content
			? typeof alert.content === 'string'
				? this.transloco.translate(alert.content, alert.data)
				: alert.content
			: undefined;

		return this.tuiAlertService
			.open(content, {
				...this.defaultOptions,
				...alert,
				label,
			})
			.pipe(take(1));
	}
}
