import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SplashService {
	readonly visible$ = new BehaviorSubject<boolean>(false);

	onShow() {
		this.visible$.next(true);
	}

	onHide() {
		this.visible$.next(false);
	}
}
